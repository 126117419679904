
export const ui = {
    init: () => {
        ui.smoothScrollAnchors();
        // window.addEventListener('resize', () => {
        //     ui.smoothScrollAnchors();
        // });
        ui.toggleSitesNav();
    },

    smoothScrollAnchors: () => {
        document.querySelectorAll('a[href^="#"]').forEach(trigger => {
            trigger.onclick = function (e) {
                e.preventDefault();
                let hash = this.getAttribute('href');
                let target = document.querySelector(hash);
                let headerOffset = 0;
                let elementPosition = target.offsetTop;
                let offsetPosition = elementPosition - headerOffset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth"
                });
            };
        });

    },

    toggleSitesNav: () => {
        let $btnToggle = document.querySelector('.btn--toggle-sites');
        if (!$btnToggle)
            return;

        let $navSites = document.querySelector('.header--sites');
        $btnToggle.addEventListener('click', () => {
            $btnToggle.classList.toggle('active');
            $navSites.classList.toggle('visible');
        });
    }

}